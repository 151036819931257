import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"

import getAdditionalData from "@ecom/ui/helpers/getAdditionalData"
import { events } from "../../../helpers/events"

type ValidationStatus = "validated" | "notvalid" | "sended" | "exceeded" | "timeout" | "resend"

export function sendDataLayerPush(status: ValidationStatus) {
  switch (status) {
    case "validated":
      pushToDataLayer({
        event: "smsverification",
        result: "success",
      })
      break
    case "notvalid":
      pushToDataLayer({ event: "smsverification", result: "fail" })
      break
    case "exceeded":
      pushToDataLayer({ event: "smsverification", result: "exceeded" })
      break
    case "timeout":
      pushToDataLayer({
        event: "smsverification",
        result: "timeout",
      })
      break
    case "sended": // custom status (not came from backend)
      pushToDataLayer({
        event: "smsverification",
        result: "sended",
      })
      break
    case "resend": // custom status (not came from backend)
      pushToDataLayer({
        event: "smsverification",
        result: "resend",
      })
      break
    default:
      break
  }
}

const API = process.env.GATSBY_API_APP_URL || "https://api-app.sovcombank.ru"

export const handleFormSubmit = (
  data: any,
  url = "/v2/app/public/insurance",
  putMethod = false,
  isValidate = false
) => {
  const additionalData = getAdditionalData()
  const dataToSend = { ...data, additionalData }
  return fetch(`${API}${url}`, {
    method: putMethod ? "PUT" : "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(isValidate ? { ...dataToSend, phoneValidate: true } : dataToSend),
  })
    .then((res) => {
      if (!res.ok) {
        throw Error(`HTTP error. Status code: ${res.status}`)
      }

      return res.json()
    })
    .catch((err) => {
      console.error("error while sending form:", err)

      pushToDataLayer(events.GAFormEvent.error)

      return Promise.reject(err)
    })
}
