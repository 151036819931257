import React, { ChangeEvent } from "react"
import PhoneWithSeven from "@ecom/ui/components/PhoneField/PhoneWithSeven"

import { FormikTouched } from "formik"
import { WrapperDataLayer } from "./WrapperDataLayer"

import * as styles from "./newfields.module.scss"

type Props = {
  name: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  error?: string
  touched?: FormikTouched<{}>
  value: string
  label?: string
}

export const PhoneField = ({ touched, error, name, label, ...restProps }: Props) => (
  <WrapperDataLayer touched={touched} error={touched ? error || "" : ""} name={name}>
    <div className={styles.fieldWrapper}>
      <PhoneWithSeven
        {...restProps}
        InputProps={{
          classes: {
            notchedOutline: styles.notchedOutline,
            root: styles.field,
          },
        }}
        placeholder="Телефон"
        helperText={touched ? error : ""}
        error={touched && !!error}
        fullWidth
        label={label}
      />
    </div>
  </WrapperDataLayer>
)
