import React from "react"

import Layout from "../components/Layouts/mainPage"
import FormContainer from "../components/FormContainer"
import VerifySMSRedisgn from "../components/VerifySMSRedisgn"
import { NewHeader } from "../components/Headers/NewHeader"

export default function VerifyPage() {
  return (
    <Layout noFooter noHeader>
      <NewHeader withButton={false} noRightSection />
      <FormContainer>
        <VerifySMSRedisgn />
      </FormContainer>
    </Layout>
  )
}
