import { useEffect } from "react"
import { FormikTouched } from "formik"
import { checkDataLayerField } from "../../../helpers/formHelpers"

type Props = {
  touched?: FormikTouched<{}>
  error?: string
  name: string
  children: JSX.Element
}

export const WrapperDataLayer = ({ touched, error, name, children }: Props) => {
  useEffect(() => {
    checkDataLayerField(touched, error, name)
  }, [error, name, touched])
  return children
}
