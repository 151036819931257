// extracted by mini-css-extract-plugin
export var acceptment = "newfields-module--acceptment--4OATV";
export var acceptmentContent = "newfields-module--acceptmentContent--ICV5J";
export var acceptmentError = "newfields-module--acceptmentError--94SG9";
export var acceptmentLabel = "newfields-module--acceptmentLabel--xqcAI";
export var acceptmentLabelRoot = "newfields-module--acceptmentLabelRoot--2xNf1";
export var acceptmentLink = "newfields-module--acceptmentLink--lFkA8";
export var acceptmentWrapper = "newfields-module--acceptmentWrapper--vFYAz";
export var expandIcon = "newfields-module--expandIcon--APs9S";
export var field = "newfields-module--field--CDthi";
export var marginBottom = "newfields-module--marginBottom--7P8zD";