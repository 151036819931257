// extracted by mini-css-extract-plugin
export var btn = "verifySMS-module--btn--lJBKV";
export var btnCancel = "verifySMS-module--btnCancel--3dcQw";
export var btnChange = "verifySMS-module--btnChange--DOjMP";
export var btnFlex = "verifySMS-module--btnFlex--rGjgN";
export var btnSave = "verifySMS-module--btnSave--xyko0";
export var changeNumber = "verifySMS-module--changeNumber--ceDn7";
export var changePhone = "verifySMS-module--changePhone--Ykhwx";
export var clearField = "verifySMS-module--clearField--qehA+";
export var container = "verifySMS-module--container--o+m6f";
export var containerClearPhone = "verifySMS-module--containerClearPhone--LXksq";
export var errormessage = "verifySMS-module--errormessage--lzOAx";
export var fieldWrapper = "verifySMS-module--fieldWrapper--6VGgq";
export var hintSended = "verifySMS-module--hintSended--5p2AY";
export var title = "verifySMS-module--title--fIFGC";
export var titleApprove = "verifySMS-module--titleApprove--gaGHY";