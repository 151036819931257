import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import clsx from "clsx"
import VerifyCodeArray from "@ecom/ui/components/VerifyCodeArray"
import Container from "@ecom/ui/components/Container"
import FormLanding from "@ecom/ui/components/FormLanding"
import SubmitButton from "@ecom/ui/components/FormLanding/fields/SubmitButton"
import Snackbar from "@material-ui/core/Snackbar"
import Alert from "@material-ui/lab/Alert"
import Button from "@material-ui/core/Button"
import { setManyIDBValues, getManyIDBValues } from "../../utils/idbUtils"
import { PhoneField } from "../FormikForm/fields/NewPhoneField"
import HelperText from "./HelperText"
import { sendDataLayerPush } from "./helpers/sendDataLayerPush"
import * as styles from "./verifySMS.module.scss"
import { sendRequest } from "../../helpers/sendRequest"

const TIMER_START = 45

export default function NewVerifySMS() {
  const [apiId, setApiId] = useState("")
  const [userPhone, setUserPhone] = useState("")
  const [time, setTime] = useState(TIMER_START)
  const [errorMsg, setErrorMsg] = useState("Неправильно введён код из СМС")
  const [isError, setIsError] = useState(false)
  const [code, setCode] = useState<Array<string>>(Array(4).fill(""))
  const [isSending, setIsSending] = useState(false)
  const [isShowHint, setIsShowHint] = useState(false)
  const [toChangePhone, setToChangePhone] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [justCase, setJustCase] = useState("")

  useEffect(() => {
    if (isError && code.some((value) => value)) {
      setIsError(false)
    }

    if (code.every((value) => value)) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      submitCode(+code.join(""))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code])

  // конвертирует номер под маску
  function convertNumberMask(number: string) {
    return number.replace(/^\+?(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/, "+7 ($2) $3-$4-$5")
  }

  // конвертирует номер для отправки
  function convertNumberMaskSend(number: string) {
    return number.replace(/[\D]+/g, "")
  }

  // очищает поле телефона
  function clearNumber() {
    setUserPhone("")
  }

  function changeInput(e: { target: { value: React.SetStateAction<string> } }) {
    const result = e.target.value
    if (typeof result === "string") {
      const convertNumber = convertNumberMaskSend(result)
      setUserPhone(convertNumber)

      if (convertNumber.length !== 11) {
        return setErrorMessage("Введите номер телефона полностью")
      }

      const re = /^(8|7)(3|4|5|6|9)/
      if (!re.test(convertNumber)) {
        return setErrorMessage("Код города/оператора должен начинаться с цифры 3, 4, 5, 6, 9")
      }
    }

    return setErrorMessage("")
  }

  useEffect(() => {
    getManyIDBValues(["phone", "requestId"]).then(([phone, requestId]: string[]) => {
      if (requestId) {
        setApiId(requestId)
      }
      if (phone) {
        setUserPhone(phone)
      }
    })
  }, [])

  useEffect(() => {
    let timerID: ReturnType<typeof setInterval>
    if (time > 0) {
      timerID = setInterval(() => {
        setTime(time - 1)
      }, 1000)
    }
    return () => clearTimeout(timerID)
  })

  function clearPhone() {
    setCode(Array(4).fill(""))
  }
  function getNewCode() {
    clearPhone()
    setIsError(false)
    sendDataLayerPush("resend")
    sendRequest(`/v2/app/public/halva/phone/validate/${apiId}`, {}, true)
      .then(({ result }: any) => {
        if (result === "exceeded") {
          setIsError(true)
          setErrorMsg("Превышен лимит запросов")
        } else {
          setTime(TIMER_START)
        }
      })
      .catch(() => {
        setTime(0)
        setIsError(true)
        navigate("/error-technical/")
      })
  }

  function submitCode(smsCode: number) {
    setIsSending(true)
    sendRequest(`/v2/app/public/halva/phone/validate/${apiId}`, { code: smsCode }, true)
      .then(({ result }: any) => {
        sendDataLayerPush(result)
        if (result !== "validated") {
          setIsError(true)
          setTimeout(() => clearPhone(), 500)
          setIsSending(false)
        } else {
          navigate("/choose-pickup-or-delivery/")
        }

        switch (result) {
          case "exceeded":
            setErrorMsg("Превышен лимит запросов")
            break
          case "timeout":
            setErrorMsg("Время кода истекло")
            break
          default:
            setErrorMsg("Неправильно введён код из СМС")
        }
      })
      .catch(() => {
        setIsError(true)
        navigate("/error-technical/")
      })
  }

  function closeAlert() {
    setIsShowHint(false)
  }

  function handleSubmit() {
    if (errorMessage) return
    sendRequest(`/v2/app/public/halva/phone/validate/${apiId}`, { phone: userPhone }, true).then(
      () => {
        try {
          setErrorMsg("")
          setIsError(false)
          setIsShowHint(true)
          sendDataLayerPush("resend")
          setToChangePhone(false)
          setManyIDBValues([["phone", userPhone]])
        } catch (error) {
          setIsError(true)
          setErrorMsg("При вводе номера произошла ошибка")
        }
      }
    )
  }

  function justCaseHandler() {
    setToChangePhone((toChange) => !toChange)
    setUserPhone(justCase)
  }

  function onChangePhone() {
    setToChangePhone((toChange) => !toChange)
    setJustCase(userPhone)
  }

  return (
    <section className="form-outer text-center">
      <Container>
        {toChangePhone ? (
          <div className={styles.container}>
            <h2 className={clsx(styles.title, styles.titleApprove)}>
              Подтвердите номер
              <br />
              телефона
            </h2>
            <FormLanding name="verifySMSForm" onSubmit={handleSubmit}>
              <div className={styles.containerClearPhone}>
                <PhoneField name="phone" value={userPhone} label="Телефон" onChange={changeInput} />
                <div className={styles.clearField} role="presentation" onClick={clearNumber} />
                {errorMessage ? <p className={styles.errormessage}> {errorMessage} </p> : null}
              </div>
              <div className={styles.btnFlex}>
                <SubmitButton className={clsx(styles.btn, styles.btnSave)}>Сохранить</SubmitButton>
                <Button className={clsx(styles.btn, styles.btnCancel)} onClick={justCaseHandler}>
                  Отмена
                </Button>
              </div>
            </FormLanding>
          </div>
        ) : (
          <>
            <h2 className={styles.title}>Введите код из СМС</h2>
            {userPhone && (
              <p className={styles.hintSended}>
                Мы отправили код подтверждения на&nbsp;номер&nbsp;{convertNumberMask(userPhone)}
              </p>
            )}
            <VerifyCodeArray
              code={code}
              setCode={setCode}
              error={isError}
              errorMsg={errorMsg}
              disabled={isSending}
              helperText={
                <HelperText
                  isRunning={time !== 0}
                  timer={time}
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={getNewCode}
                />
              }
            />
            <div className={styles.changeNumber}>
              <p className={styles.changePhone}>Ошиблись в номере телефона?&nbsp;</p>
              <button onClick={onChangePhone} type="button">
                Изменить
              </button>
            </div>
            <Snackbar
              open={isShowHint}
              // eslint-disable-next-line react/jsx-no-bind
              onClose={closeAlert}
              autoHideDuration={2000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert elevation={6} variant="filled" severity="info">
                Телефон обновлен
              </Alert>
            </Snackbar>
          </>
        )}
      </Container>
    </section>
  )
}
