import React from "react"

import * as styles from "./helperText.module.scss"

type HelperTextProps = {
  isRunning: boolean
  timer: number
  onClick: () => void
}

export default function HelperText({ isRunning, timer, onClick }: HelperTextProps) {
  return (
    <div className={styles.helperText}>
      {isRunning ? (
        <span className={styles.timer}>
          Запросить код повторно можно через <b>{timer} сек</b>
        </span>
      ) : (
        <button type="button" className={styles.resend} onClick={onClick}>
          Выслать код повторно
        </button>
      )}
    </div>
  )
}
